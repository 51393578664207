
import { Vue, Component, Watch } from "vue-property-decorator";
import { CentroCustoService, ContaCorrenteService, ContaGerencialService, FormaPagamentoService, ReceitaService, SituacaoReceitaParcelaService} from "@/core/services/financeiro";
import { ReceitaParcela, ReceitaBaixa,Receita, RetornoBancario, Remessa} from "@/core/models/financeiro";
import { EmpresaService } from "@/core/services/compras/EmpresaService";
import { EmpreendimentoService, PessoaService } from "@/core/services/cadastros";
import { mixins } from "vue-class-component";
import ListPage from "@/components/shared/ListPage";
import { ConstruirQueryParams, SituacaoColor, TableToExcel } from '@/assets/scripts/helper';
import { EnumTipoOperacaoFinanceira } from "@/core/models/cadastros";

@Component
export default class ListaReceita extends mixins(Vue,ListPage) {
  service = new ReceitaService();
  item = new Receita(); 
  filter = new Model();
  remessa = new Remessa();

  itemParcela = new ReceitaParcela(); 
  baixa = new ReceitaBaixa();
  retornoBancario = new RetornoBancario();
  gerarCancelarBoletos:boolean = false;

  empresas = [];
  lista: [] = [];
  pessoas = [] 
  situacoes = [];
  empreendimentos:any = [];
  listaCentroCusto = [];
  listaContaGerencial = [];
  contaCorrentes = [];
  formasPagamento = [];
  selectedItems:any = [];
 
  totalLista = 0;
  dataPagamentoFinal = null
  loading: boolean = false;
  dialogCadastro: boolean = false;
  dialogRetornoBancario: boolean = false;
  dialogVisualizar: boolean = false;
  dialogEditar: boolean = false;
  dialogBaixar: boolean = false;
  dialogValoresAdicional:boolean = false;
  dialogRemessa:boolean = false;
  sheet:boolean = false;
  allSelected =  false;
  
  options: any = {
    itemsPerPage: 20,
  };

  headers: any[] = [
    { text: "", value: "actions", sortable: false },
    { text: "Nº do Contrato", value: "receita.contrato/numero"},
    { text: "Empresa", value: "receita.empresa/nome"},
    { text: "Num. Doc.", value: "receita.numeroDocumento" },
    { text: "Parcela", value: "parcela"},
    { text: "Cliente", value: "receita.cliente/nome"},
    { text: "Boleto", value: "urlBoleto", sortable: false },
    { text: "Vencimento", value: "dataVencimento"},
    { text: "Valor", value: "valor"},
    { text: "Situação", value: "situacao.nome" },
    { text: "Saldo", value: "saldo", sortable: false},
    { text: "Valor Pago", value: "valorPago", sortable: false}
  ];    

   breadCrumbLinks: any[] = [
    { text: 'Home', href: '/financeiro/painel/kpi',},
    { text: 'Lista de Receita', disabled: true, href: '/financeiro/painel/despesas'}
  ]

  @Watch("options", { deep: true })
  Atualizar() { 
    const { page, itemsPerPage, sortBy, sortDesc, search, columns  } = this.options;
    this.loading = true;    
    this.service.ListarParcelas(page,itemsPerPage,sortBy,sortDesc,search, columns, this.filter, '' , '', 'Baixas, Receita.Contrato, Receita.Empresa, Receita.Cliente, Situacao').then(
      (res) => {
        this.lista = res.data.items;
        this.totalLista = res.data.count;
        this.sheet = false
      },
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      })
    .finally(() => (this.loading = false)); 
  } 
   
  Novo() {
    this.item = new Receita();
    this.dialogCadastro = true;
  } 

  RetornoBancario() {
    this.retornoBancario = new RetornoBancario();
    this.dialogRetornoBancario = true;
  }

  Remessa(){
    this.remessa = new Remessa();
    this.dialogRemessa = true;
  }

  ValoresAdicionais(item){
    this.itemParcela = item;
    this.dialogValoresAdicional = true;
  }

  DetalharParcela(item) {
    this.service.ObterParcelaPorId(item.id, "Receita.Tipo, Receita.Cliente, Situacao, Baixas.FormaPagamento, Receita.Classificacoes.CentroCusto, Receita.Classificacoes.ContaGerencial, Receita.Contrato.Empreendimento, Receita.Contrato.UnidadePrincipal,  Receita.Contrato.TipoProduto, Receita.TipoDocumento").then((res) => {       
      this.itemParcela = res.data
      this.dialogVisualizar = true;
    },
    (err) => {
      if (!err.response) {
        this.$swal("Aviso", "Não foi possível acessar a API", "error");
      } else if (err.response.status == 403) {
        this.$swal("Aviso", err.response.data.message, "warning" );
      } else {
        this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
      }
    }); 
  }
 
  EditarReceita(item){
    this.service.ObterPorId(item.receita.id, "Parcelas.Baixas, Classificacoes").then((res) => {       
      this.item = res.data;
      this.item.dataEmissao = new Date(res.data.dataEmissao).yyyymmdd();
      this.dialogCadastro = true;
    },
    (err) => {
      if (!err.response) {
        this.$swal("Aviso", "Não foi possível acessar a API", "error");
      } else if (err.response.status == 403) {
        this.$swal("Aviso", err.response.data.message, "warning" );
      } else {
        this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
      }
    });
  }

  ClonarReceita(item){
    this.service.ObterPorId(item.receita.id, "Parcelas.Baixas, Classificacoes").then((res) => {   
          
      this.item = res.data;
      this.item.id = 0;

      this.item.parcelas.forEach(x => {
        x.id = 0;
        x.baixas = [];
        x.situacaoId = 1;
        x.linhaDigitavelBoleto = '';
      })
      this.item.classificacoes.forEach(x => {
        x.id = 0;
      })

      this.item.documentos = [];

      this.item.dataEmissao = new Date(res.data.dataEmissao).yyyymmdd();
      this.dialogCadastro = true;
    },
      
    (err) => {
      if (!err.response) {
        this.$swal("Aviso", "Não foi possível acessar a API", "error");
      } else if (err.response.status == 403) {
        this.$swal("Aviso", err.response.data.message, "warning" );
      } else {
        this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
      }
    }); 
  }
  
  EditarParcela(item) {
     this.service.ObterParcelaPorId(item.id).then((res) => {       
      this.itemParcela = res.data;
      this.itemParcela.dataVencimento = new Date(res.data.dataVencimento).yyyymmdd();
      this.dialogEditar = true;
    },
    (err) => {
      if (!err.response) {
        this.$swal("Aviso", "Não foi possível acessar a API", "error");
      } else if (err.response.status == 403) {
        this.$swal("Aviso", err.response.data.message, "warning" );
      } else {
        this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
      }
    });
  } 
    
  BaixarParcela(item) {
    this.service.ObterParcelaPorId(item.id, 'Receita').then(res =>{
      this.itemParcela = res.data;
      this.itemParcela.dataVencimento = new Date(res.data.dataVencimento).yyyymmdd();
      this.dialogBaixar = true;
    })
  } 
  
  VisualizarBoleto(item){ 
    this.service.ObterParcelaPorId(item.id, 'Transacoes').then(
      (res) => {
        let ultimaTransacao = res.data.ultimaTransacao;

        if (item.tipoOperacaoId === EnumTipoOperacaoFinanceira.Boleto) {
          this.abrirUrlNoNovaJanela("financeiroBoleto", { transacaoId: ultimaTransacao.id });
        } 
        else if (item.tipoOperacaoId === EnumTipoOperacaoFinanceira.Gateway) {
          if (item.tipoGatewayId === 3) {
            this.abrirUrlNoNovaJanela("RelBoletoSafra", { codigoZoop: item.codigoZoop });
          }else{
            window.open(item.urlBoleto, "_blank");
          }
        }
        else if (item.tipoOperacaoId === EnumTipoOperacaoFinanceira.Banco) {
          this.DownloadDocumento(item.id);
        }
      });
  } 

  abrirUrlNoNovaJanela(nomeRota, queryParams) {
    let routeLink = this.$router.resolve({
      name: nomeRota,
      query: ConstruirQueryParams(queryParams),
    });
    window.open(routeLink.href, "_blank");
  }

  GerarBoletos(){
    if(this.selectedItems.length == 0){
      return this.$swal("Aviso", "É necessário selecionar uma ou mais parcela para gerar o boleto" , "warning")
    }

     this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja Gerar os Boletos?",
      icon: "question", 
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.service.GerarBoletos(this.selectedItems.map(x=> x)).then(
          (res) => {
            if (res.status == 200) {
              this.selectedItems = [];
              this.Atualizar(); 
              return res;
            }
          },
          (err) => this.$swal("Aviso", err.response.data, "error")
        );
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading(),
    }).then((result: any) => {
      if (result.value) {
        this.$swal(
          "Aviso",
          result.value.data,
          result.value.status == 200 ? "success" : "warning"
        );
      }
    }); 
  }

  CancelarBoletos(){
     this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja cancelar os Boletos?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.service.CancelarBoletos(this.selectedItems.map(x=> x)).then(
          (res) => {
            if (res.status == 200) {
              this.selectedItems = [];
              this.Atualizar(); 
              return res;
            }
          },
          (err) => this.$swal("Aviso", err.response.data, "error")
        );
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading(),
    }).then((result: any) => {
      if (result.value) {
        this.$swal(
          "Aviso",
          result.value.data,
          result.value.status == 200 ? "success" : "warning"
        );
      }
    }); 
  }

  GerarBoleto(item){
    this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja Gerar o Boleto?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.service.GerarBoleto(item.id).then(
          (res) => {
            if (res.status == 200) {
              this.Atualizar(); 
              return res;
            }
          },
          (err) => this.$swal("Aviso", err.response.data, "error")
        );
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading(),
    }).then((result: any) => {
      if (result.value) {
        this.$swal(
          "Aviso",
          result.value.data,
          result.value.status == 200 ? "success" : "warning"
        );
      }
    });
  }

  CancelarParcela(item) {
    this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja cancelar o registro atual?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.service.CancelarParcela(item.id).then(
          (res) => {
            if (res.status == 200) {
              this.Atualizar();
              return res;
            }
          },
          (err) => this.$swal("Aviso", err.response.data, "error")
        );
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading(),
    }).then((result: any) => {
      if (result.value) {
        this.$swal(
          "Aviso",
          result.value.data,
          result.value.status == 200 ? "success" : "warning"
        );
      }
    });
  } 

  EstornarBoleto(item){
    this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja estornar o boleto?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.service.CancelarBoleto(item.id).then(
          (res) => {
            if (res.status == 200) {
              this.Atualizar();
              return res;
            }
          },
          (err) => this.$swal("Aviso", err.response.data, "error")
        );
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading(),
    }).then((result: any) => {
      if (result.value) {
        this.$swal(
          "Aviso",
          result.value.data,
          result.value.status == 200 ? "success" : "warning"
        );
      }
    });
  }
   
  Consultar(){
    this.loading = true;
    this.options.page = 1;
    const { page, itemsPerPage, sortBy, sortDesc, search, columns } = this.options;

    this.service.ListarParcelas(page, itemsPerPage, sortBy,sortDesc,this.search,this.headers, this.filter, '' , '', 'Baixas, Receita.Contrato, Receita.Empresa, Receita.Cliente, Situacao').then(
      (res) => {
        this.lista = res.data.items;
        this.totalLista = res.data.count;
        this.loading = false;  
      },  
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      }
    );   
    this.sheet = false;
  } 

  ExportarExcel(tabela: string, worksheet: string){
    TableToExcel(tabela, worksheet);
  }  

  ResetFiltro(){
    this.filter = { codigoBanco:0,contaCorrenteId:0,numero:0,empresaId:0,tipoId:0,numeroDocumento:"",formaPagamentoId:0, clienteId:0, situacaoId:[1,4], dataVencimentoInicial:"", dataVencimentoFinal:"",dataPagamentoInicial:"", dataPagamentoFinal:"", empreendimentoId:0, centroCustoId:0, contaGerencialId:0}
  }

  SituacaoColor(item){
    return SituacaoColor(item);
  }

  buttons = [
    { id: 1, label: "5", color: "red lighten-2", active: true, dias: -5 },
    { id: 2, label: "15", color: "red lighten-2", active: true, dias: -15 },
    { id: 3, label: "30", color: "red lighten-2", active: true, dias: -30 },
    { id: 4, label: "HOJE", color: "blue lighten-2", active: true, dias: 0 },
    { id: 5, label: "30", color: "green lighten-2", active: true, dias: 30 },
    { id: 6, label: "15", color: "green lighten-2", active: true, dias: 15 },
    { id: 7, label: "5", color: "green lighten-2", active: true, dias: 5 },
  ]

  toggleButton(button){
    if (button.active) {
      this.filterByDias(button.dias);
      this.resetButtons();
      button.active = false;
    } else {
      button.active = true;
      this.filter.dataVencimentoInicial ="";
      this.filter.dataVencimentoFinal ="";
      this.Atualizar();
    }
  }

  filterByDias(dias) {
    const date = new Date(new Date().setDate(new Date().getDate() + dias));
    this.filter.dataVencimentoInicial = new Date().yyyymmdd();
    this.filter.dataVencimentoFinal = new Date().yyyymmdd();

    if(dias >=0){
      this.filter.dataVencimentoFinal = new Date(date).yyyymmdd();
    }else if(dias < 0){
      this.filter.dataVencimentoInicial = new Date(date).yyyymmdd();
    } 
    this.Atualizar();
  }

  resetButtons() {
    this.buttons.forEach((button) => (button.active = true));
  }

  DownloadDocumento(id: number){
    this.loading = true;
      this.service.DownloadDocumento(id).then(
        res => 
        {
          let file = new Blob([res.data], {type: res.headers["content-type"]});
          let fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        },
          (err) => { 
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }}
    )
    .finally(() => this.loading = false);
  }

  onItemSelected(selectedItems) {
    this.selectedItems = selectedItems;
  }

 SelecionarTodos() {
    if (this.allSelected) {
      this.selectedItems = [];
    } else {
      this.selectedItems = this.lista.map((x:any) => x.id);
    }
    this.allSelected = !this.allSelected;
  }

  get textselecionartodasparcelas(){
    if (this.selecionarTodosEmpreendimentos) return 'Desmarcar'
    if (this.SelecionarAlgunsEmpreendimentos) return 'Selecionando...'
    return 'Selecionar todos'
  }

  get selecionarTodosEmpreendimentos(){
    return this.selectedItems.length === this.lista.length;
  }

  get SelecionarAlgunsEmpreendimentos(){
    return this.selectedItems.length > 0 && !this.selecionarTodosEmpreendimentos;
  }
  
  mounted(){ 
    new FormaPagamentoService().ListarTudo().then( res => {
      this.formasPagamento = res.data.items;
    })

    new ContaCorrenteService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id, nome', '').then(res =>{
      this.contaCorrentes = res.data.items
    })

    new EmpreendimentoService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id, nome', '').then(
      res => this.empreendimentos = res.data.items
    )
 
    new PessoaService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id,nome,cnpjCpf, nomeCnpjCpf', '').then(
      res => this.pessoas = res.data.items
    )

    new SituacaoReceitaParcelaService().ListarTudo().then(
      res => this.situacoes = res.data.items
    )

    new EmpresaService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id, nome', '').then(
      res => this.empresas = res.data.items
    )

    new CentroCustoService().Listagem().then((res) => {
      this.listaCentroCusto = res.data;
    });

    new ContaGerencialService().Listagem().then((res) => {
      this.listaContaGerencial = res.data;
    });
  }
} 

 class Model {
    situacaoId = [1,4];
    contaCorrenteId:number = 0;
    numero:number = 0;
    empresaId:number = 0;
    tipoId:number = 0;
    numeroDocumento:string = "";
    formaPagamentoId: number = 0;
    clienteId: number = 0;
    dataVencimentoInicial:string = "";
    dataVencimentoFinal:string = "";
    dataPagamentoInicial:string = "";
    dataPagamentoFinal:string = "";
    empreendimentoId:number = 0;
    centroCustoId:number = 0;
    contaGerencialId:number = 0;
    codigoBanco:number = 0;
  }
